// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".OrderChangeSpinner-module__spinner___1tbX8 {\n  opacity: 0;\n  transform: scale(0.8);\n  transition: all 0.15s ease-in-out;\n}\n\n.OrderChangeSpinner-module__visible___WWUs2 {\n  opacity: 1;\n  transform: none;\n}", "",{"version":3,"sources":["/rails/app/javascript/src/components/OrderChangeSpinner/OrderChangeSpinner.module.scss","OrderChangeSpinner.module.scss"],"names":[],"mappings":"AAEA;EACE,UAAA;EACA,qBAAA;EACA,iCAAA;ACDF;;ADIA;EACE,UAAA;EACA,eAAA;ACDF","file":"OrderChangeSpinner.module.scss","sourcesContent":["@import \"@variables\";\n\n.spinner {\n  opacity: 0;\n  transform: scale(0.8);\n  transition: all 0.15s ease-in-out;\n}\n\n.visible {\n  opacity: 1;\n  transform: none;\n}\n",".spinner {\n  opacity: 0;\n  transform: scale(0.8);\n  transition: all 0.15s ease-in-out;\n}\n\n.visible {\n  opacity: 1;\n  transform: none;\n}"]}]);
// Exports
exports.locals = {
	"spinner": "OrderChangeSpinner-module__spinner___1tbX8",
	"visible": "OrderChangeSpinner-module__visible___WWUs2"
};
module.exports = exports;
